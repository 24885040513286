import React from 'react'
import * as dates from 'date-arithmetic'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

function rangeFunc(start, end, unit = 'day') {
  let current = start
  const days = []
  while (dates.lte(current, end, unit)) {
    days.push(current)
    current = dates.add(current, 1, unit)
  }
  return days
}

function inRange(e, start, end, accessors) {
  const eStart = dates.startOf(accessors.start(e), 'day')
  const eEnd = accessors.end(e)
  const startsBeforeEnd = dates.lte(eStart, end, 'day')
  const endsAfterStart = !dates.eq(eStart, eEnd, 'minutes')
    ? dates.gt(eEnd, start, 'minutes')
    : dates.gte(eEnd, start, 'minutes')
  return startsBeforeEnd && endsAfterStart
}

const WeeklyAgenda = ({ accessors, localizer, length, date, events, onSelectEvent }) => {
  const renderWeek = (day, events) => {
    events = events.filter((e) =>
      inRange(e, dates.startOf(day, 'day'), dates.endOf(day, 'day'), accessors)
    )
    return events.map((event, idx) => {
      return (
        <div key={idx}>
          {idx === 0 && (
            <div className="date-wrap">
              <span>Week of {localizer.format(day, 'DD')}</span>
              <div className="line" />
            </div>
          )}
          <div
            className="agenda-event"
          >
            <div className="agenda-event-title" onClick={() => onSelectEvent(event)}>{accessors.title(event)}</div>
            {timeRangeLabel(day, event)}
            <div className="agenda-event-details">{event.desc}</div>
          </div>
        </div>
      )
    }, [])
  }

  const timeRangeLabel = (day, event) => {
    const end = accessors.end(event)
    const start = accessors.start(event)

    if (!accessors.allDay(event)) {
      if (dayjs(start).day() === dayjs(end).day()) {
        const timePeriod = `${dayjs(start).format('h:mma')} – ${dayjs(
          end
        ).format('h:mma')}`
        return (<div className="agenda-event-time">{timePeriod}</div>)
      } else {
        const startDate = dayjs(start).format('DD-MM YYYY, h:mma')
        const endDate = dayjs(end).format('DD-MM YYYY, h:mma')
        return (<div className="agenda-event-time">{startDate} – {endDate}</div>)
      }
    }
  }

  const end = dates.add(date, length, 'day')
  const range = rangeFunc(date, end, 'day')
  events = events.filter((event) => inRange(event, date, end, accessors))
  events.sort((a, b) => +accessors.start(a) - +accessors.start(b))

  return (
    <div className="mt-4">
      <h2>Weekly Agenda</h2>
      {events.length !== 0
        ? range.map((day, idx) => renderWeek(day, events, idx))
        : 'No event dates in range'}
    </div>
  )
}

WeeklyAgenda.title = (start, { localizer }) => {
  const end = dates.add(start, 1, 'month')
  return localizer.format({ start, end }, 'agendaHeaderFormat')
}

WeeklyAgenda.navigate = (date, action) => {
  const sDate = dayjs(date).startOf('month').toDate()
  switch (action) {
    case 'PREV':
      return dates.add(sDate, -4, 'month')
    case 'NEXT':
      return dates.add(sDate, 4, 'month')
    default:
      return date
  }
}

WeeklyAgenda.propTypes = {
  events: PropTypes.array,
  date: PropTypes.instanceOf(Date),
  length: PropTypes.number,
  selected: PropTypes.object,
  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object.isRequired,
}

export default WeeklyAgenda