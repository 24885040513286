import React, { useState, useMemo } from "react"
import { useAtom } from "jotai"
import { userStore, schoolsAtom } from "./store"
import { Form, Modal, Button, Row, Col } from "react-bootstrap"
import { JSON_FETCH_HEADERS } from './constants'
import { log, makeIdNameOptions } from "./utils"

const emptyFilters = {
  school_id: null,
}

const PotentialFriendAction = (props) => {
  const { currentChildId, friendId, notification, refreshUser } = props

  const requestFriend = (e) => {
    e.preventDefault()
  
    fetch(`/api/request_friend`, {
        method: "post",
        headers: JSON_FETCH_HEADERS,
        body: JSON.stringify({
          "requester_id": currentChildId,
          "requestee_id": friendId,
        })
    })
    .then((response) => response.json())
    .then(data => {
        if ("error" in data) {
            alert(data["error"])
        } else {
            refreshUser(currentChildId)
        }
    })
    .catch(error => alert("Potential Friend Request Error: " + error))
  }
  

  if (!notification) {
    // make this an "Add Friend" button
    return (
      <Button variant="primary" onClick={requestFriend}>Request Friend</Button>
    )
  }
  if (notification.status === "pending") {
    return (<div><i>pending friend</i></div>)
  }
  if (notification.status === "requested") {
    return (<div><i>awaiting your approval!</i></div>)
  }
  if (notification.status === "rejected") {
    return (<div><i>rejected friend</i></div>)
  }
}

const FindFriendsModal = (props) => {
  const { refreshUser } = props
  const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
  const [schools, setSchools] = useAtom(schoolsAtom)

  const [show, setShow] = useState(false)
  const [filters, setFilters] = useState(emptyFilters)
  const [allChildren, setAllChildren] = useState([])

  const friendIds = useMemo(() => userLoggedIn.friends[userLoggedIn.currentChildId]?.map(friend => friend.friend_id) ?? [], [userLoggedIn.friends])

  const notificationLookup = useMemo(() => {
    const lookup = {}
    for (const notification of userLoggedIn.notifications) {
      if (notification.child_id === userLoggedIn.currentChildId) {
        lookup[notification.friend_id] = notification
      }
    }
    return lookup
  }, [userLoggedIn.notifications])
  
  // Object.fromEntries(
  //     Object.values(userLoggedIn.notifications).map(
  //   friend => {
  //     if (userLoggedIn.currentChildId === friend.child_id) {
  //       return [friend.friend_id]: friend.status
  //     }
  //   }
  // ), [userLoggedIn.currentChildId, userLoggedIn.notifications])

  // const pendingFriendIds = useMemo(() => Object.values(userLoggedIn.notifications).map(
  //   friend => {
  //     if (userLoggedIn.currentChildId === friend.child_id && friend.status === "pending") {
  //       return friend.friend_id
  //     }
  //   }
  // ), [userLoggedIn.currentChildId, userLoggedIn.notifications])


  const schoolOptions = useMemo(() => makeIdNameOptions(Object.values(schools), "school_id", "school_name"), [schools])

  const filteredChildren = useMemo(() => {
    if (filters.school_id) {
      return allChildren.filter(child => child.school_id === filters.school_id)
    }
    return allChildren
  }, [allChildren, filters])

  const resetFilters = () => {
    setFilters(emptyFilters)
  }

  const handleClose = () => setShow(false)
  const handleShow = () => {
    getAllChildren()
    resetFilters()
    setShow(true)
  }

  // get all children
  const getAllChildren = () => {
    if (allChildren.length > 0) {
      return
    }
    fetch(`/api/get_all_children`, {
      method: "get",
      headers: JSON_FETCH_HEADERS,
    })
    .then((response) => response.json())
    .then(data => {
        if ("error" in data) {
          alert(data["error"])
        } else {
          // child_name is first name plus first initial of last, like "Bob R."
          //  [{
          //     "child_id": row.child_id,
          //     "user_id": row.user_id,
          //     "child_name": child_name,
          //     "school_id": row.school_id,
          //     "invite_code": row.invite_code,
          // }]
          setAllChildren(data)
        }
    })
    .catch(error => console.error("Get all children error:", error))
  }

  return (
    <>
      <div className="sidebar-item">
        {/* • <a className="sidebar-link" onClick={handleShow}>Find a Friend</a> */}
          <Button variant="primary" onClick={handleShow}>
            Find a Friend
          </Button>
        </div>
      {/* <Button variant="primary" onClick={handleShow}>
        Find a Friend
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Find a Friend</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="FindFriends.school">
              <Form.Label column sm={3}>Filter by School:</Form.Label>
              <Col sm={9}>
                <Form.Select
                  name="school_id"
                  value={filters?.school_id ?? ""}
                  onChange={(e) => {setFilters({...filters, school_id: parseInt(e.target.value, 10)})}}
                >
                  <option value="">- no filter -</option>
                  {schoolOptions}
                </Form.Select>
              </Col>
            </Form.Group>
          </Form>

          <div>
            <h4 className="mt-4">Potential Friends</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>Child Name</th>
                  <th>School</th>
                  <th>Action</th>
                </tr>
              </thead>
              {filteredChildren.map(child => {
                if (friendIds.includes(child.child_id)) {
                  return null
                }
                return (
                    <tr key={child.child_id}>
                      <td>{child.child_name}</td>
                      <td>{schools?.[child.school_id]?.school_name ?? ""}</td>
                      <td>
                        <PotentialFriendAction
                          refreshUser={refreshUser}
                          currentChildId={userLoggedIn.currentChildId}
                          friendId={child.child_id}
                          notification={notificationLookup[child.child_id] ?? null}
                        />
                      </td>
                    </tr>
                )
              })}
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FindFriendsModal
